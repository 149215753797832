import { useScroll, animated } from 'react-spring'

import styles from './ProgressBar.css'

export function ProgressBar({ layoutClassName }) {
  const { scrollYProgress } = useScroll()

  return (
    <animated.div
      role="progressbar"
      className={cx(styles.component, layoutClassName)}
      style={{ width: scrollYProgress.to(x => `${x * 100}%`) }}
    />
  )
}
